@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary-color: #e81478;
  --secondary-color: #1a1819;
  --text-primary: #1a1819;
  --text-secondary: #666870;
  --text-pink: #df146d;
  --color-natural: #23262f;
  --color-natural-4: #777e91;
  --color-warning: #ffdd72;
  --color-black-main: #000929;
  --color-white: #fcfcfd;
  --background-white: #ffffff;
  --color-background: #f5f5f5;
  --color-stroke: #d9e0e7;
  --border-color: #c3c6d3;
  --color-gray: #f3f6f8;
  --color-green: rgb(60, 179, 113);
  --desk-background-color: #f9f9fb;
  --fonts-primary: "Inter", sans-serif;
}
